import axios from 'axios';
import './App.css';

const App = () => {

	const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/henken/upload';
	const CLOUDINARY_UPLOAD_PRESET = 'tz5izblv';
	const CLOUDINARY_FOLDER = 'upload';
	const CLOUDINARY_TAGS = ['collage'];

	const body = document.querySelector('body');
	const blendModes = ['multiply','screen','overlay','color-burn','hard-light','soft-light'];
	let rand = (min,max) => Math.floor(Math.floor(Math.random() * max) + min);


	const getImages = () => {
		axios.get('https://res.cloudinary.com/henken/image/list/collage.json').then(function(res){
			var images = res.data.resources;
			for(var i=0; i<10; i++){
				var ri = images[rand(0,images.length)];
				var fileName = ri.public_id;
				var fileExt = ri.format;
				var fileSrc = 'https://res.cloudinary.com/henken/image/upload/' + fileName + '.' + fileExt;
				var newImg = document.createElement('img');
				newImg.src = fileSrc;
				newImg.style.width = rand(20,40) + 'vw';
				newImg.style.top = rand(-20,80) + '%';
				newImg.style.left = rand(-20,80) + '%';
				newImg.style.opacity = '0.7';
				newImg.style.transform = 'rotate(' + rand(0,360) + 'deg)';
				newImg.style.mixBlendMode = blendModes[rand(0,blendModes.length)];
				body.appendChild(newImg);
			}
		}).catch(function(err){
			console.log('Failed to retrieve images.');
		});
	}

	document.addEventListener("DOMContentLoaded", function(){
		getImages();
	});

	const uploadImage = (files) => {
		console.log(files);
		for(var i=0; i<files.length; i++){
			const formData = new FormData()
			formData.append('file', files[i])
			formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
			formData.append('folder', CLOUDINARY_FOLDER);
			formData.append('tags', CLOUDINARY_TAGS)
			axios.post(CLOUDINARY_URL, formData).then(function(res){
				var src = res.data.secure_url;
				// src = src[0] + 'image/upload' + '/w_100' + src[1];
				console.log(src + ' uploaded successfully');
				// https://res.cloudinary.com/henken/image/upload/w_100/v1631059827/upload/tdnfjdbtxt0qlz5qoqs9.jpg
				// uploaded.innerHTML += '<img src="' + src + '" />';
			}).catch(function(err){
				console.log(err);
			});
		}
	}

	return (
		<div className="container">
			<label>
				<input
					type="file"
					multiple="multiple"
					onChange={(e)=>{
						uploadImage(e.target.files)
					}}
				/>
				Browse Files
			</label>

			<div className="uploaded"></div>
		</div>
	);
}

export default App;
